<template>
  <div id="compensation">
    <van-nav-bar title="运费补偿申请" left-arrow  @click-left="$router.go(-1)"/>
      <van-field
        v-model="phone"
        name="手机号码"
        label="手机号码"
        label-width="3rem"
        placeholder="请输入手机号码"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="alipay"
        name="支付宝账号"
        label="支付宝账号"
        label-width="3rem"
        placeholder="请输入支付宝账号"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="alipayName"
        name="支付宝真实姓名"
        label="支付宝真实姓名"
        label-width="3rem"
        placeholder="请输入支付宝真实姓名"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="code"
        name="补偿码"
        label="补偿码"
        label-width="3rem"
        placeholder="请输入补偿码"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="authCode"
        name="短信验证码"
        label="短信验证码"
        label-width="3rem"
        placeholder="请输入短信验证码"
        :rules="[{ required: true }]"
      >
        <van-button slot="button" size="small" @click="handleClickOnCode">{{ buttonText }}</van-button>
      </van-field>
      <div style="margin: 16px;">
        <van-button round block type="info" @click="handleSubmit" color="#fcc800">
          提交
        </van-button>
      </div>
      <div style="margin: 50px;">
        <van-notice-bar wrapable :scrollable="false" text="亲爱的用户，请您填写正确的支付宝账户及实名认证的姓名（打开支付宝---右下角我的---点击页面最上方昵称---在此查看身份认证的姓名及支付宝账号）,如有其他疑问您可拨打华米客服热线400--000-6666（工作时间8:00-22:00），感谢您的支持，祝您生活愉快！"/>
      </div>
  </div>
</template>

<script>
export default {
  name: 'compensation',
  data () {
    return {
      phone: null,
      alipay: null,
      alipayName: null,
      code: null,
      authCode: null,
      buttonText: '获取验证码',
      baseUrl2: 'https://sale.huami.com/v1/'
    }
  },
  created () {

  },
  methods: {
    handleSubmit () {
      if (!this.phone) {
        this.$toast('手机号码不能为空！')
        return false
      }
      if (!(/^1[3456789]\d{9}$/.test(this.phone))) {
        this.$toast('手机号码不正确！')
        return false
      }
      if (!this.alipay) {
        this.$toast('支付宝账号不能为空！')
        return false
      }
      if (!this.alipayName) {
        this.$toast('支付宝账号真实姓名不能为空！')
        return false
      }
      if (!this.code) {
        this.$toast('补偿码不能为空！')
        return false
      }
      if (!this.authCode) {
        this.$toast('短信验证码不能为空！')
        return false
      }

      let tip = '请仔细检查您输入的信息是否正确，因输入的账户不正确造成的损失由用户承担'
      var self = this
      if (confirm(tip)) {
        this.$http.post(self.baseUrl2 + 'compensations', { 'app': 'amazfit', 'phone': self.phone, 'authCode': self.authCode, 'alipay': self.alipay, 'alipayName': self.alipayName, 'code': self.code }).then(function (res) {
          self.$toast('已申请成功，请稍后检查您的支付宝账号')
          self.phone = null
          self.alipay = null
          self.alipayName = null
          self.code = null
          self.authCode = null
        }).catch(function (error) {
          self.$toast(error.response.data.message)
        })
      }
    },

    handleClickOnCode () {
      if (!this.phone) {
        this.$toast('请先输入您的手机号！')
        return false
      }
      if (!(/^1[3456789]\d{9}$/.test(this.phone))) {
        this.$toast('请输入正确的手机号！')
        return false
      }

      var self = this
      this.$http.post(self.baseUrl2 + 'auth-codes', { phone: self.phone }).then(function (res) {
        self.buttonText = 60
        self.timer = setInterval(function () {
          self.buttonText--
          if (self.buttonText === 0) {
            clearInterval(self.timer)
            self.buttonText = '获取验证码'
          }
        }, 1000)
      }).catch(function (error) {
        self.$toast(error.response.data.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
